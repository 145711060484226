import {RootState} from '../store';

export const getNotificationsList = (state: RootState) =>
  state.notifications.notificationsList;

export const getNewNotification = (state: RootState) =>
  state.notifications.newNotification;

export const getIsNewNotification = (state: RootState) =>
  state.notifications.isNewNotification;

export const getIsNotificationsLoading = (state: RootState) =>
  state.notifications.isNotificationsLoading;

export const getCountNotificationsUnread = (state: RootState) =>
  state.notifications.countNotificationsUnread;

export const getCurrentNotificationsPage = (state: RootState) =>
  state.notifications.currentNotificationsPage;
