import {createSlice} from '@reduxjs/toolkit';
import {INDEX_PAGE_SIZE_DEFAULT} from 'src/shared/constants/AppConst';

interface DataGridInitialState {
  searchValue?: string;
  title: string;
  showSearchField?: boolean;
  searchPlaceholder?: string;
  labelAddButton?: string;
  addUrl?: string;
  refreshTable?: boolean;
  titleTable?: string;
  dataTable?: any;
  isLoadingTable?: boolean;
  total?: number;
  pageSize?: number;
  currentPage?: number;
}

const initialState: DataGridInitialState = {
  searchValue: '',
  title: '',
  showSearchField: false,
  searchPlaceholder: '',
  labelAddButton: '',
  addUrl: '',
  refreshTable: false,
  titleTable: '',
  dataTable: [],
  isLoadingTable: false,
  total: 0,
  pageSize: INDEX_PAGE_SIZE_DEFAULT,
  currentPage: 1,
};

const dataGridSlice = createSlice({
  name: 'dataGrid',
  initialState: initialState,
  reducers: {
    setTitleDataGrid: (state, action) => {
      state.title = action.payload;
    },
    setShowSearchFieldDataGrid: (state, action) => {
      state.showSearchField = action.payload;
    },
    setSearchPlaceholderDataGrid: (state, action) => {
      state.searchPlaceholder = action.payload;
    },
    setLabelAddButtonDataGrid: (state, action) => {
      state.labelAddButton = action.payload;
    },
    setAddUrlDataGrid: (state, action) => {
      state.addUrl = action.payload;
    },
    setSearchValueDataGrid: (state, action) => {
      state.searchValue = action.payload;
    },
    setRefreshTableDataGrid: (state, action) => {
      state.refreshTable = action.payload;
    },
    setTitleTableDataGrid: (state, action) => {
      state.titleTable = action.payload;
    },
    setDataTableDataGrid: (state, action) => {
      state.dataTable = action.payload;
    },
    setIsLoadingTableDataGrid: (state, action) => {
      state.isLoadingTable = action.payload;
    },
    setTotalTableDataGrid: (state, action) => {
      state.total = action.payload;
    },
    setCurrentPageTableDataGrid: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSizeTableDataGrid: (state, action) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  setTitleDataGrid,
  setShowSearchFieldDataGrid,
  setSearchPlaceholderDataGrid,
  setLabelAddButtonDataGrid,
  setAddUrlDataGrid,
  setSearchValueDataGrid,
  setRefreshTableDataGrid,
  setTitleTableDataGrid,
  setDataTableDataGrid,
  setIsLoadingTableDataGrid,
  setTotalTableDataGrid,
  setCurrentPageTableDataGrid,
  setPageSizeTableDataGrid,
} = dataGridSlice.actions;
export default dataGridSlice.reducer;
