import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {io} from 'socket.io-client';
import {getIsAuthenticated} from 'src/domain/app/redux/auth/auth-selectors';
import {
  setCountNotificationsUnread,
  setNewNotification,
} from 'src/domain/app/redux/notifications/notification-slice';
import {
  COUNT_NOTIFICATIONS,
  USER_INFO,
  remoteSocketIoUrl,
} from 'src/shared/constants/AppConst';
import MainUtils from 'src/shared/utils/main';
import urlJoin from 'url-join';
import {getCountNotificationsUnread} from 'src/domain/app/redux/notifications/notification-selectors';

export const useInitSocketIo = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const countNotificationsUnread = useSelector(getCountNotificationsUnread);
  const [checkJoin, setCheckJoin] = useState(false);
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    if (isAuthenticated) {
      setSocket(
        io(urlJoin(remoteSocketIoUrl!), {
          transports: ['websocket', 'polling'],
        }),
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !checkJoin && socket) {
      socket?.on('connect', function () {
        socket.emit('checkRoom');
      });
      setCheckJoin(true);
    }
  }, [isAuthenticated, checkJoin, socket]);

  useEffect(() => {
    if (isAuthenticated && socket) {
      socket?.on('receiveNotification', (item: any) => {
        const userInfo = JSON.parse(localStorage.getItem(USER_INFO) ?? '');
        if (
          !MainUtils.isEmptyValue(item) &&
          item?.RecieverUserEmail === userInfo?.email
        ) {
          dispatch(setNewNotification(item));
          dispatch(
            setCountNotificationsUnread(Number(countNotificationsUnread) + 1),
          );
          localStorage.setItem(
            COUNT_NOTIFICATIONS,
            `${Number(countNotificationsUnread) ? Number(countNotificationsUnread) + 1 : 1}`,
          );
        }
      });
      socket?.on('disconnect', () => {
        socket.on('connect', function () {
          console.log('reconnect');
        });
      });
    }
  }, [isAuthenticated, socket, countNotificationsUnread]);
};
