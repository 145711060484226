import {Avatar, Col, Row} from 'antd';
import responseAvatar from '../../../assets/images/popups/icn_120_record.svg';
import './index.style.less';
import {useSelector} from 'react-redux';
import {getDirection} from 'src/domain/app/redux/auth/auth-selectors';
import Modal from 'src/shared/components/modal';

const AppConfirmPopup = ({
  loading,
  visible,
  setVisible,
  handleOK,
  title,
  okText,
  cancelText,
}: any) => {
  const direction = useSelector(getDirection);
  return (
    <Modal
      width={'700px'}
      open={visible}
      cancelText={cancelText}
      onCancel={() => setVisible && setVisible(false)}
      onOk={handleOK}
      okText={okText}
      okButtonProps={{loading: loading}}>
      <div className={'confirmPopup'} dir={direction}>
        <Row justify={'center'}>
          <Avatar size={150} shape={'square'} src={responseAvatar} />
        </Row>
        <Row justify={'center'}>
          <Col className={'confirmTitle'}>{title}</Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AppConfirmPopup;
