export enum ManageRoles {
  List = 'READ_ROLES_LIST',
  Details = 'READ_ROLE_DETAILS',
  Create = 'CREATE_ROLE',
  Update = 'UPDATE_ROLE',
  Delete = 'DELETE_ROLE',
  Select = 'SELECT_ROLES',
}

export enum ManagePermissions {
  List = 'READ_PERMISSIONS_LIST',
  Details = 'READ_PERMISSION_DETAILS',
  Update = 'UPDATE_PERMISSION',
}

export enum ManageUser {
  List = 'READ_USERS_LIST',
  Create = 'CREATE_USER',
  CreateAdmin = 'CREATE_ADMIN',
  UpdateUserProfile = 'UPDATE_USER_PROFILE',
  UpdateUserRole = 'UPDATE_USER_ROLE',
  Profile = 'READ_USER_PROFILE',
  Delete = 'DELETE_USER',
  Select = 'SELECT_USERS',
  ActiveAndDeactive = 'ACTIVATE_/_DEACTIVATE_USER',
}

export enum ManageCompanies {
  List = 'READ_COMPANIES_LIST',
  Details = 'READ_COMPANY_DETAILS',
  Create = 'CREATE_COMPANY',
  Update = 'UPDATE_COMPANY',
  Delete = 'DELETE_COMPANY',
  Select = 'SELECT_COMPANIES',
  UpdateCompanyProfile = 'EDIT_MY_COMPANY_PROFILE',
  DetailsCompanyProfile = 'READ_MY_COMPANY_PROFILE',
  LockAndUnLockProfile = 'LOCK_UNLOCK_COMPANY_PROFILE',
}

export enum ManageServices {
  List = 'READ_SERVICES_LIST',
  Details = 'READ_SERVICE_DETAILS',
  Create = 'CREATE_SERVICE',
  Update = 'UPDATE_SERVICE',
  Delete = 'DELETE_SERVICE',
  Select = 'SELECT_SERVICES',
}

export enum ManageCareRequests {
  List = 'READ_CARE_REQUESTS_LIST',
  Details = 'READ_CARE_REQUEST_DETAILS',
}

export enum ManageNotifications {
  List = 'READ_NOTIFICATIONS_LIST',
  MakeNotificationIsRead = 'MAKE_NOTIFICATION_IS_READ',
}

export enum ManageClaiProfile {
  List = 'READ_CLAIM_BUSINESS_PROFILE_REQUESTS_LIST',
  Details = 'READ_CLAIM_BUSINESS_PROFILE_REQUEST_DETAILS',
  Approve = 'APPROVE_CLAIM_BUSINESS_PROFILE_REQUEST',
}
