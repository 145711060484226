const languageData = [
  {
    languageId: 'arabic',
    code: 'ar',
    name: 'Arabic',
    icon: 'sa',
  },
  {
    languageId: 'english',
    code: 'en',
    name: 'English',
    icon: 'us',
  },
];
export default languageData;
