import {Fragment, useState} from 'react';
import {Badge, Col, Dropdown, List, Row, Space, Spin} from 'antd';
import './index.style.less';
import {useDispatch, useSelector} from 'react-redux';
import {FaRegBell} from 'react-icons/fa6';
import Button from 'src/shared/components/button';
import {
  getCountNotificationsUnread,
  getCurrentNotificationsPage,
  getIsNotificationsLoading,
  getNotificationsList,
} from 'src/domain/app/redux/notifications/notification-selectors';
import {NotificationTypeValue} from 'src/shared/constants/AppEnums';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {notificationsService} from 'src/shared/services/notifications';
import {
  COUNT_NOTIFICATIONS,
  formatTimeWithMonthAndDay,
} from 'src/shared/constants/AppConst';
import MainUtils from 'src/shared/utils/main';
import {
  setCountNotificationsUnread,
  setCurrentNotificationsPage,
  setIsNotificationsLoading,
  setNotificationsList,
} from 'src/domain/app/redux/notifications/notification-slice';
import {NotificationsDto} from 'src/shared/services/notifications/dto/notification-dto';
import {
  UserDefaultGrayIcon,
  UserDefaultIcon,
} from 'src/shared/components/icons';
import moment from 'moment';

const Notifications = () => {
  const {messages} = useIntl();
  const dispatch = useDispatch();
  const notificationsList = useSelector(getNotificationsList);
  const isNotificationsLoading = useSelector(getIsNotificationsLoading);
  const countNotificationsUnread = useSelector(getCountNotificationsUnread);
  const currentNotificationsPage = useSelector(getCurrentNotificationsPage);
  const navigate = useNavigate();

  const [openNotificationsDropdown, setOpenNotificationsDropdown] =
    useState(false);

  const goToNotificationDetails = (item: any) => {
    switch (item.type) {
      case NotificationTypeValue.CareRequest:
        navigate(`/care-requests/details/${item?.metadata?.Id}`);
        break;

      case NotificationTypeValue.ClaimBusinessProfileRequest:
        navigate(`/claims-profile/details/${item?.metadata?.Id}`);
        break;

      default:
        break;
    }
    setOpenNotificationsDropdown(false);
  };

  const handleNotificationDetails = async (item: NotificationsDto) => {
    if (item?.isRead) {
      goToNotificationDetails(item);
    } else {
      if (!MainUtils.isEmptyValue(item?.id))
        try {
          await notificationsService.makeIsRead(item?.id).then((response) => {
            const data = response?.Data;
            dispatch(
              setNotificationsList({
                ...notificationsList,
                data: notificationsList?.data?.map((item) => {
                  if (item?.id === data?.id) {
                    return data;
                  } else return item;
                }),
              }),
            );
            if (data) {
              goToNotificationDetails(item);
            }
          });
        } catch (error) {
          console.error(error);
        }
    }
  };

  const goToNotificationsList = () => {
    dispatch(setCurrentNotificationsPage(currentNotificationsPage + 1));
  };

  const menu = (
    <Spin spinning={isNotificationsLoading}>
      <List className='dropdown-notifications-list-container'>
        {notificationsList && notificationsList?.data?.length > 0 ? (
          notificationsList?.data?.map((item) => {
            return (
              <List.Item
                key={item?.id}
                className={`notification-item pointer ${item?.isRead ? '' : 'unreadNotification'}`}
                onClick={() => handleNotificationDetails(item)}>
                <Space className='fullContent'>
                  {item?.isRead ? (
                    <UserDefaultGrayIcon width={40} height={40} />
                  ) : (
                    <UserDefaultIcon width={40} height={40} />
                  )}
                  <Row className='fullContent' gutter={[0, 3]}>
                    <Col span={24}>{item?.message}</Col>
                    <Col span={24}>
                      {moment(new Date(item?.createdDate)).format(
                        formatTimeWithMonthAndDay,
                      )}
                    </Col>
                  </Row>
                </Space>
              </List.Item>
            );
          })
        ) : (
          <List.Item className='label-notifications justify-content-center'>
            {messages['common.thereAreNoNewNotifications'].toString()}
          </List.Item>
        )}
        {notificationsList &&
        notificationsList?.totalItems > 4 &&
        notificationsList?.data?.length < notificationsList?.totalItems ? (
          <List.Item
            className='see-more pointer'
            onClick={() => goToNotificationsList()}>
            {messages['common.seeMore'].toString()}
          </List.Item>
        ) : (
          <></>
        )}
      </List>
    </Spin>
  );

  const handleOpenNotifications = async () => {
    if (countNotificationsUnread && Number(countNotificationsUnread) > 0)
      try {
        dispatch(setIsNotificationsLoading(true));
        await notificationsService
          .getAllNotifications({
            Page: 1,
            PageSize: 4,
            Sorts: `-createdDate`,
          })
          .then((response) => {
            const data = response?.Data;
            if (!MainUtils.isEmptyValue(data)) {
              dispatch(setNotificationsList(data));
              dispatch(setCountNotificationsUnread(0));
              localStorage.removeItem(COUNT_NOTIFICATIONS);
            }
          });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsNotificationsLoading(false));
      }
  };

  return (
    <Fragment>
      <Dropdown
        dropdownRender={() => menu}
        trigger={['click']}
        open={openNotificationsDropdown}
        onOpenChange={async (open) => {
          setOpenNotificationsDropdown(open);
          if (open) {
            await handleOpenNotifications();
          }
        }}
        placement='bottomRight'
        overlayStyle={{
          zIndex: 1052,
          minWidth: 150,
        }}>
        <Badge
          count={
            countNotificationsUnread ? Number(countNotificationsUnread) : 0
          }
          className='align-content-center'>
          <Button type='link' className='no-padding'>
            <FaRegBell size={20} />
          </Button>
        </Badge>
      </Dropdown>
    </Fragment>
  );
};

export default Notifications;
