import {AiOutlineLock, AiOutlineTeam} from 'react-icons/ai';
import {FaRegBuilding} from 'react-icons/fa';
import {
  ManageCareRequests,
  ManageClaiProfile,
  ManageCompanies,
  ManageRoles,
  ManageUser,
} from 'src/shared/constants/permissions-enum';
import {AiOutlineProfile} from 'react-icons/ai';
import {AiOutlineSolution} from 'react-icons/ai';

const routesConfig = [
  {
    id: 'companies',
    title: 'companies',
    messageId: 'companies.sideBarName',
    icon: <FaRegBuilding />,
    type: 'item',
    path: '/companies',
    permissionKey: ManageCompanies.List,
  },
  {
    id: 'care-requests',
    title: 'care-requests',
    messageId: 'careRequests.sideBarName',
    icon: <AiOutlineProfile />,
    type: 'item',
    path: '/care-requests',
    permissionKey: ManageCareRequests.List,
  },
  {
    id: 'claims-profile',
    title: 'claims-profile',
    messageId: 'claimsProfile.sideBarName',
    icon: <AiOutlineSolution />,
    type: 'item',
    path: '/claims-profile',
    permissionKey: ManageClaiProfile.List,
  },
  {
    id: 'roles',
    title: 'roles',
    messageId: 'rolesManagement.sideBarName',
    icon: <AiOutlineLock />,
    type: 'item',
    path: '/roles',
    permissionKey: ManageRoles.List,
  },
  {
    id: 'users',
    title: 'users',
    messageId: 'usersManagement.sideBarName',
    icon: <AiOutlineTeam />,
    type: 'item',
    path: '/users',
    permissionKey: ManageUser.List,
  },
];
export default routesConfig;
