import {createSlice} from '@reduxjs/toolkit';
import {DataListDto} from 'src/shared/common-dtos/data-list-dto';
import {SocketIoItemDto} from 'src/shared/common-dtos/socket-io-item-dto';
import {COUNT_NOTIFICATIONS} from 'src/shared/constants/AppConst';
import {NotificationsDto} from 'src/shared/services/notifications/dto/notification-dto';
interface NotificationInitialState {
  notificationsList: DataListDto<NotificationsDto>;
  newNotification: SocketIoItemDto | undefined;
  isNewNotification: boolean;
  isNotificationsLoading: boolean;
  countNotificationsUnread: number;
  currentNotificationsPage: number;
}

const initialState: NotificationInitialState = {
  notificationsList: {
    data: [],
    totalItems: 0,
  },
  newNotification: undefined,
  isNewNotification: false,
  isNotificationsLoading: false,
  countNotificationsUnread: localStorage.getItem(COUNT_NOTIFICATIONS)
    ? Number(localStorage.getItem(COUNT_NOTIFICATIONS))
    : 0,
  currentNotificationsPage: 1,
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload;
    },
    setNewNotification: (state, action) => {
      state.newNotification = action.payload;
    },
    setIsNewNotification: (state, action) => {
      state.isNewNotification = action.payload;
    },
    setIsNotificationsLoading: (state, action) => {
      state.isNotificationsLoading = action.payload;
    },
    setCountNotificationsUnread: (state, action) => {
      state.countNotificationsUnread = action.payload;
    },
    setCurrentNotificationsPage: (state, action) => {
      state.currentNotificationsPage = action.payload;
    },
  },
});

export const {
  setNotificationsList,
  setNewNotification,
  setIsNewNotification,
  setIsNotificationsLoading,
  setCountNotificationsUnread,
  setCurrentNotificationsPage,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
