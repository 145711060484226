import {createSlice} from '@reduxjs/toolkit';
import {DetailsClaimProfileDto} from 'src/shared/services/claims-profile/dto/details-claim-profile-dto';

interface ClaimsProfileInitialState {
  claimsProfileList: Array<DetailsClaimProfileDto> | undefined;
  claimProfileDetails: DetailsClaimProfileDto | undefined;
  isClaimsProfileListLoading: boolean | undefined;
}

const initialState: ClaimsProfileInitialState = {
  claimsProfileList: [],
  claimProfileDetails: undefined,
  isClaimsProfileListLoading: false,
};

const claimsProfileSlice = createSlice({
  name: 'claimsProfile',
  initialState: initialState,
  reducers: {
    setClaimsProfileList: (state, action) => {
      state.claimsProfileList = action.payload;
    },
    setClaimProfileDetails: (state, action) => {
      state.claimProfileDetails = action.payload;
    },
    setIsClaimsProfileListLoading: (state, action) => {
      state.isClaimsProfileListLoading = action.payload;
    },
  },
});

export const {
  setClaimsProfileList,
  setClaimProfileDetails,
  setIsClaimsProfileListLoading,
} = claimsProfileSlice.actions;

export default claimsProfileSlice.reducer;
