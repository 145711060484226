import {Col, Modal as ModalAntd, ModalProps, Row} from 'antd';
import Button from '../button';
import {useSelector} from 'react-redux';
import {getDirection} from 'src/domain/app/redux/auth/auth-selectors';
import {useIntl} from 'react-intl';

interface IModalProps extends ModalProps {
  title?: string;
  loading?: boolean;
}

const Modal = (props: IModalProps) => {
  const {loading} = props;
  const direction = useSelector(getDirection);
  const {messages} = useIntl();

  return (
    <ModalAntd
      open={props.open}
      destroyOnClose
      maskClosable={false}
      title={
        props.title ? (
          <h3 className='title-modal'>{props.title?.toString()}</h3>
        ) : null
      }
      okButtonProps={props.okButtonProps}
      onCancel={props.onCancel}
      footer={
        <Row justify='end' dir={direction} gutter={[10, 10]}>
          <Col>
            <Button type='default' onClick={props.onCancel}>
              {props.cancelText ?? messages['common.cancel'].toString()}
            </Button>
          </Col>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              onClick={props.onOk}
              loading={loading}>
              {props.okText ?? messages['common.ok'].toString()}
            </Button>
          </Col>
        </Row>
      }
      {...props}>
      {props.children}
    </ModalAntd>
  );
};

export default Modal;
