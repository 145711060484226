import {useIntl} from 'react-intl';
import '../AuthWrapper.style.less';
import AppConfirmPopup from '../../../domain/core/AppConfirmPopup';
import {useDispatch, useSelector} from 'react-redux';
import {
  getLoading,
  getVisibleLogout,
} from 'src/domain/app/redux/auth/auth-selectors';
import {
  setLoading,
  setVisibleLogout,
} from 'src/domain/app/redux/auth/auth-slice';
import {useCookies} from 'react-cookie';
import {
  AUTH_TOKEN,
  FCM_TOKEN_KEY,
  PERMISSIONS_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_EXPIRE_DATE,
  USER_INFO,
} from 'src/shared/constants/AppConst';
import {useNavigate} from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const visibleLogout = useSelector(getVisibleLogout);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const {messages} = useIntl();
  const [, , removeCookie] = useCookies([AUTH_TOKEN]);

  const logout = () => {
    dispatch(setLoading(true));
    removeCookie(AUTH_TOKEN, {path: '/', sameSite: true});
    removeCookie(REFRESH_TOKEN_KEY, {path: '/', sameSite: true});
    dispatch(setVisibleLogout(false));
    dispatch(setLoading(false));
    localStorage.removeItem(FCM_TOKEN_KEY);
    localStorage.removeItem(PERMISSIONS_KEY);
    localStorage.removeItem(TOKEN_EXPIRE_DATE);
    localStorage.removeItem(USER_INFO);
    navigate('/sign-in');
  };

  return (
    <AppConfirmPopup
      loading={loading}
      visible={visibleLogout}
      setVisible={(data: any) => dispatch(setVisibleLogout(data))}
      handleOK={logout}
      title={messages['common.logout.title']}
      okText={messages['common.ok']}
      cancelText={messages['common.cancel']}
    />
  );
};

export default Logout;
