import {Image, Tag} from 'antd';
import {useIntl} from 'react-intl';
import './index.style.less';
import GoogleIconSVG from 'src/assets/icon/logo-google.svg';
import UserDefaultIconSVG from 'src/assets/icon/user-default.svg';
import UserDefaultGrayIconSVG from 'src/assets/icon/user-default-gray.svg';
import CloseCircleIconSVG from 'src/assets/icon/close-circle.svg';

declare type SafeNumber = number | `${number}`;

interface IconProps {
  className?: string;
  width?: SafeNumber | undefined;
  height?: SafeNumber | undefined;
  onClick?: () => void;
}
const GoogleIcon: React.FC<IconProps> = (props) => {
  const {messages} = useIntl();
  return (
    <Image
      src={GoogleIconSVG}
      alt={messages['common.google'].toString()}
      width={17.25}
      height={17.25}
      preview={false}
      className={`${props.className ?? ''}`}
      {...props}
    />
  );
};

const UserDefaultIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={UserDefaultIconSVG}
      alt={messages['common.user'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const UserDefaultGrayIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={UserDefaultGrayIconSVG}
      alt={messages['common.user'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const CloseCircleIcon: React.FC<IconProps> = (props) => {
  const {width, height} = props;
  const {messages} = useIntl();
  return (
    <Image
      src={CloseCircleIconSVG}
      alt={messages['common.close'].toString()}
      width={width ?? 17.25}
      height={height ?? 17.25}
      preview={false}
      className={
        props.className ? `icon-container ${props.className}` : 'icon-container'
      }
      {...props}
    />
  );
};

const NotFound = () => {
  return <Tag className='ant-tag-disable-pointer'></Tag>;
};

export {
  GoogleIcon,
  UserDefaultIcon,
  UserDefaultGrayIcon,
  CloseCircleIcon,
  NotFound,
};
