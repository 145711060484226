import {createSlice} from '@reduxjs/toolkit';
import {CompanyDetatilsDto} from 'src/shared/services/companies/dto/company-details-dto';

interface CompaniesInitialState {
  companyDetails: CompanyDetatilsDto | undefined;
}

const initialState: CompaniesInitialState = {
  companyDetails: undefined,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
  },
});

export const {setCompanyDetails} = companiesSlice.actions;

export default companiesSlice.reducer;
