import {PERMISSIONS_KEY} from 'src/shared/constants/AppConst';
import MainUtils from 'src/shared/utils/main';

const hasPermission = (permissionKey: Array<string> | string | undefined) => {
  const permissionsString = localStorage.getItem(PERMISSIONS_KEY);
  const permissions = !MainUtils.isEmptyValue(permissionsString)
    ? JSON.parse(permissionsString ?? '')
    : [];

  return (
    (!MainUtils.isEmptyValue(permissionKey) &&
      !MainUtils.isEmptyValue(permissions) &&
      (typeof permissionKey === 'object'
        ? permissionKey?.filter((permission: string) =>
            permissions?.includes(permission),
          )?.length > 0
        : permissions?.some((permission: string) =>
            permission?.includes(`${permissionKey}`),
          ))) ||
    MainUtils.isEmptyValue(permissionKey)
  );
};
export default hasPermission;
