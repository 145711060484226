import {combineReducers} from 'redux';
import authSlice from './auth/auth-slice';
import dataGridSlice from './data-grid/data-grid-slice';
import rolesManagementSlice from './roles-management/roles-management-slice';
import usersManagementSlice from './users-management/users-management-slice';
import companiesSlice from './companies/companies-slice';
import googleMapSlice from './google-map/google-map-slice';
import careRequestsSlice from './care-requests/care-requests-slice';
import notificationsSlice from './notifications/notification-slice';
import claimsProfileSlice from './claims-profile/claims-profile-slice';

export let reducer = combineReducers({
  auth: authSlice,
  dataGrid: dataGridSlice,
  companies: companiesSlice,
  rolesManagement: rolesManagementSlice,
  usersManagement: usersManagementSlice,
  googleMap: googleMapSlice,
  careRequests: careRequestsSlice,
  claimsProfile: claimsProfileSlice,
  notifications: notificationsSlice,
});
