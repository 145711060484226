import {createSlice} from '@reduxjs/toolkit';
import {DeatilsCareRequestDto} from 'src/shared/services/care-requests/dto/details-care-request-dto';

interface CareRequestsInitialState {
  careRequestsList: Array<DeatilsCareRequestDto> | undefined;
  careRequestDetails: DeatilsCareRequestDto | undefined;
  isCareRequestsListLoading: boolean | undefined;
}

const initialState: CareRequestsInitialState = {
  careRequestsList: [],
  careRequestDetails: undefined,
  isCareRequestsListLoading: false,
};

const careRequestsSlice = createSlice({
  name: 'careRequests',
  initialState: initialState,
  reducers: {
    setCareRequestsList: (state, action) => {
      state.careRequestsList = action.payload;
    },
    setCareRequestDetails: (state, action) => {
      state.careRequestDetails = action.payload;
    },
    setIsCareRequestsListLoading: (state, action) => {
      state.isCareRequestsListLoading = action.payload;
    },
  },
});

export const {
  setCareRequestsList,
  setCareRequestDetails,
  setIsCareRequestsListLoading,
} = careRequestsSlice.actions;

export default careRequestsSlice.reducer;
