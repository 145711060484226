import ApiService from 'src/shared/utils/base-api/api-service';
import {ResponseDto} from 'src/shared/common-dtos/response-dto';
import {ParamsDto} from 'src/shared/common-dtos/params-dto';
import {DataListDto} from 'src/shared/common-dtos/data-list-dto';
import {NotificationsDto} from './dto/notification-dto';

class NotificationsService extends ApiService {
  constructor() {
    super({
      baseURL: '/notification/Notification',
    });
  }

  public getAllNotifications(
    input?: ParamsDto,
  ): Promise<ResponseDto<DataListDto<NotificationsDto>>> {
    return this.get('/GetAll', {
      params: input,
    });
  }

  public makeIsRead(
    notificationId?: string,
  ): Promise<ResponseDto<NotificationsDto>> {
    return this.put(`/MakeIsRead/${notificationId}`);
  }
}

export const notificationsService = new NotificationsService();
